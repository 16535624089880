// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/__generated__/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/__generated__/_index.tsx");
  import.meta.hot.lastModified = "1708481747210.479";
}
// REMIX HMR END

/* eslint-disable */
/* This is a auto generated file for building the project */

import { useState } from "react";
import { Body, Link, Form } from "@webstudio-is/sdk-components-react-remix";
import { Box, Image, Text, Bold, Heading, Paragraph, Label, Input, Textarea, Button } from "@webstudio-is/sdk-components-react";
export const fontAssets = [{
  "id": "fc5457a0-9130-4f63-99c1-3cbe273d0691",
  "name": "DMSans-VariableFont_opsz_wght_024TU8xKTl_JWSQ8iEN-W.ttf",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 238984,
  "type": "font",
  "createdAt": "2023-08-30T20:58:14.976Z",
  "format": "ttf",
  "meta": {
    "family": "DM Sans",
    "variationAxes": {
      "opsz": {
        "name": "Optical size",
        "min": 9,
        "default": 9,
        "max": 40
      },
      "wght": {
        "name": "Weight",
        "min": 100,
        "default": 400,
        "max": 1000
      }
    }
  }
}];
export const imageAssets = [{
  "id": "cd0fb247-a45e-4b7b-aac5-e5af4d5ca43f",
  "name": "Coming_Soon_Website_j1EIeie0rPDJuRMB92Cy5.png",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 136142,
  "type": "image",
  "format": "png",
  "createdAt": "2024-02-18T18:16:42.942Z",
  "meta": {
    "width": 1920,
    "height": 1091
  }
}, {
  "id": "efdd2a37-7dde-44b6-b65d-bc3d738400e0",
  "name": "Uxible_Z--qw6g1GQD11ALYrIGww.png",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 157501,
  "type": "image",
  "format": "png",
  "createdAt": "2024-02-18T16:29:44.297Z",
  "meta": {
    "width": 1600,
    "height": 1600
  }
}, {
  "id": "6e6c76c6-45bb-41ce-a0b4-cd72e1c10526",
  "name": "Launch_Pictures_Ivan_Diaz_NBkBowh9dEq5IiXABRiFO.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 2397371,
  "type": "image",
  "format": "jpg",
  "createdAt": "2024-02-18T16:07:05.839Z",
  "meta": {
    "width": 3456,
    "height": 5184
  }
}, {
  "id": "9237951c-22f9-4a66-a76c-05b32729e608",
  "name": "Man_sitting_5XKjRZTRoxDh7ycOvJoTR.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 2058239,
  "type": "image",
  "format": "jpg",
  "createdAt": "2024-02-18T15:55:39.934Z",
  "meta": {
    "width": 3793,
    "height": 5689
  }
}, {
  "id": "a62b4876-7e00-498f-99c6-ab40daa1ae5b",
  "name": "marketing-social_v_1E1S7FuQrhxNSxRTqJf.png",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 1776060,
  "type": "image",
  "format": "png",
  "createdAt": "2024-02-07T21:31:16.337Z",
  "meta": {
    "width": 2422,
    "height": 1572
  }
}, {
  "id": "2119a892-74b9-445f-826f-5dfd14b3d9f1",
  "name": "josh-rocklage-HAAsKeZbHAc-unsplash_NE1qfBY6TNANGiAFuXiKA.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 1074399,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-09-18T18:23:56.511Z",
  "meta": {
    "width": 3240,
    "height": 2160
  }
}, {
  "id": "973a83e0-11b9-491a-b292-b7583c2fac66",
  "name": "rocco-dipoppa-_uDj_lyPVpA-unsplash_KeTV-4SQ04K-NYYOPSopk.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 536800,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-08-31T19:50:19.787Z",
  "meta": {
    "width": 1920,
    "height": 1216
  }
}, {
  "id": "b96fe4c2-52d4-41a3-b196-8486d9cba1df",
  "name": "Twitter_Icon_White_BVEhCDE-LW9kXpzM-xuKP.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 845,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-31T05:27:48.409Z",
  "meta": {
    "width": 16,
    "height": 13
  }
}, {
  "id": "06432619-c391-4d15-a0e3-1cfbd5bcca9d",
  "name": "LinkedIn_Icon_White_rDiQBCYd17Isq8l8CP2Nn.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 749,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-31T05:27:48.406Z",
  "meta": {
    "width": 15,
    "height": 15
  }
}, {
  "id": "3403ce87-d9b6-4f0f-bd00-ac891f4caf2a",
  "name": "Facebook_Icon_White_GwMppwRTnN0knd6Pgzgcd.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 529,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-31T05:27:48.335Z",
  "meta": {
    "width": 9,
    "height": 18
  }
}, {
  "id": "2578e5f8-b137-4c82-a29a-20fdad5fd5e1",
  "name": "Pinterest_Icon_White_Y5L0cQG-4f3vBZY-4e2uU.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 1213,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-31T05:27:48.328Z",
  "meta": {
    "width": 13,
    "height": 17
  }
}, {
  "id": "1a440c67-b3af-4ce6-85a0-fdc0c6e6a9f2",
  "name": "leo-wieling-bG8U3kaZltE-unsplash_0XfLoiqtZtJeO11OGIl1Q.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 278983,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-08-31T01:43:26.453Z",
  "meta": {
    "width": 1280,
    "height": 1920
  }
}, {
  "id": "bf6bd9fa-cc07-4a87-bb33-b68f0c9618bf",
  "name": "luke-thornton-JNl7o-Mj2cc-unsplash_0qWTlSv7B4rmUKvB_Y1cl.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 613792,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-08-31T01:26:25.342Z",
  "meta": {
    "width": 1920,
    "height": 1280
  }
}, {
  "id": "4cab4c9d-3c3f-4a16-855d-bb7fb25ff0b4",
  "name": "kyle-smith-x0dtRYZovLo-unsplash_BahcweLIUAdV9LkMyFU6v.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 650871,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-08-31T01:26:25.273Z",
  "meta": {
    "width": 1280,
    "height": 1920
  }
}, {
  "id": "e753cac6-d978-4b4c-9c52-155e2367ffae",
  "name": "alex-wong-RIo45CycGCs-unsplash_5z3vcfZKOncrqJsrUk0Dg.jpg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 520576,
  "type": "image",
  "format": "jpeg",
  "createdAt": "2023-08-31T01:26:25.160Z",
  "meta": {
    "width": 1920,
    "height": 1280
  }
}, {
  "id": "624f0661-c4de-4504-b7c5-e534e38472b6",
  "name": "Facebook_1bZ8k5eTlmlCbZ8bVdC_D.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 535,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-30T06:01:39.102Z",
  "meta": {
    "width": 8,
    "height": 18
  }
}, {
  "id": "b7ab152f-0263-45b3-a0af-84196c2d9be6",
  "name": "Twitter_8ER4uHstrL3pVvvllcY5i.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 865,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-30T06:01:39.096Z",
  "meta": {
    "width": 16,
    "height": 13
  }
}, {
  "id": "fb7c20d8-f647-49f6-a811-a43bac5c7077",
  "name": "LinkedIn_cWCVUBxqrbsxuDSdF1gAE.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 755,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-08-30T06:01:39.094Z",
  "meta": {
    "width": 15,
    "height": 15
  }
}, {
  "id": "e04b2f18-eff3-43d7-a8a5-e0fe61d8f3d3",
  "name": "logo-icon-color_7lPMMn5N1_sztj7QUZvvx.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 16276,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-03-24T21:43:47.942Z",
  "meta": {
    "width": 280,
    "height": 211
  }
}, {
  "id": "fd5358bb-1c6a-444a-88e0-a372dd971196",
  "name": "cib-github_lGytwpLtQ6Y-OqmnrFxq2.svg",
  "description": null,
  "projectId": "e002cda7-6667-477f-bbbe-af527f3034c6",
  "size": 1458,
  "type": "image",
  "format": "svg",
  "createdAt": "2023-02-25T03:33:13.012Z",
  "meta": {
    "width": 24,
    "height": 24
  }
}];
export const pageData = {};
export const user = {
  "email": "krichkorn@gmail.com"
};
export const projectId = "e002cda7-6667-477f-bbbe-af527f3034c6";
export const getPageMeta = ({
  params,
  resources
}) => {
  return {
    title: "Home",
    description: undefined,
    excludePageFromSearch: false,
    socialImageAssetId: "6e6c76c6-45bb-41ce-a0b4-cd72e1c10526",
    socialImageUrl: undefined,
    status: undefined,
    redirect: undefined,
    custom: []
  };
};
const Page = ({
  params: PageParams
}) => {
  _s();
  let [dialogOpen, set$dialogOpen] = useState(false);
  let [accordionValue, set$accordionValue] = useState("0");
  let [formState, set$formState] = useState("initial");
  return <Body data-ws-id="quj5c9wlKZkBNobFjf6z8" data-ws-component="Body" className="c8gl1fq c1mspmwk ckt52sm c1hgade c1o5t2ve">
    <Box data-ws-id="U0eLy8cwYjbyhYjGsuaqk" data-ws-component="Box" className="c12nfkjf cjoxzzt c1b4zs23 cgy0mkz c1yr0vgi c1sgbb1f clmqj2b c153hhwy c11gr7ii ch8sau3 ctedtxi chkmtte">
      <Box data-ws-id="ZfyNsCf1V4zhxHAonl4cS" data-ws-component="Box" className="cny9q3f crroenv cqv4916 ckqwaap c4zr7el c13slisu c11yckyv c1vqdua3">
        <Box data-ws-id="eIuIz-vn013J101LpgPJ_" data-ws-component="Box" className="cny9q3f crroenv cqv4916 c1sdrmnm c1y5pray cox1mb0 c1ogv9vg c1rwkgjj c1bfzhwt cv1fbrr cpik6mp cczm91w c1y9zveq c12k0vei c1vkdfk4 cqyft4a cbbkm22 c1b8mokv c1shd0vz cmce8l8 c13daquy c1d237vu c6v5ddz" />
        <Image data-ws-id="F_th5JmI1RmSm8BS0ds6J" data-ws-component="Image" src={"/assets/Launch_Pictures_Ivan_Diaz_NBkBowh9dEq5IiXABRiFO.jpg"} loading={"eager"} alt={"Hero Image"} width={3456} height={5184} className="cmjoqmf c135xw6u ct8i9vr cq2ww50 c1gnju34 cp5b59h cidi7mz cyil8lz cvpwmn9" />
      </Box>
      <Link data-ws-id="7TvqUM05cylp9H4f3f_bH" data-ws-component="Link" className="c1v92b24 c18mbfxh c1mspmwk ct8i9vr c1pc5yxj c1p1fgry c10z6flc cqb9ahs c1ackfbg c1au2fq0 c1du65tt c7e77dn c197a8a0 c1xv8a62 c10h9lo9 c1s21sg3 c12l0war cibqieo c1bunxwv">
        <Image data-ws-id="bJLE8mrRdvefnKx2zUnXQ" data-ws-component="Image" src={"/assets/Uxible_Z--qw6g1GQD11ALYrIGww.png"} width={150} height={150} loading={"lazy"} className="c1pc5yxj c1ackfbg csco5tq c3plwn1" />
      </Link>
      <Box data-ws-id="UlHRFl6IgJgX_uztSWo8Z" data-ws-component="Box" className="c12nfkjf cjoxzzt c1q8va2w c1lmoard c18mbfxh cac9vuz c1gmemb9 ct8i9vr c1pc5yxj c1p1fgry cbwrfyh ck0dix4 c1aku05i cx3jtq7 c10z6flc cqb9ahs c1hzupn4 csllo0k caqynba c7e77dn c197a8a0 cr7s72u cm7988n c1xv8a62 c10h9lo9 cg21r5v c12l0war cibqieo ckl77xc">
        <Heading data-ws-id="i3nSK9TAdtI6sxhsSSckU" data-ws-component="Heading" tag={"h1"} className="c1odyjf c1lxwxiw c1il523u c1f3qls9 cx9r5ry c1cg91nn c1oatzf7 c1asy1v8 c89dght">
          {"Get Ready,"}
        </Heading>
        <Image data-ws-id="-MACBxc15FLQvGf3Pf_Mq" data-ws-component="Image" src={"/assets/Coming_Soon_Website_j1EIeie0rPDJuRMB92Cy5.png"} width={370} height={210} loading={"eager"} className="c1odyjf c1lxwxiw c1il523u c1f3qls9 cycdw1e c1pc5yxj cqxde8f c1l7tcwg c1lv3dt c19v379m c1vwpedc csii7p5 c1co9oev c18vdear cpxb9kg c1oatzf7 c19rmv3q c197a8a0 c1ackfbg" />
      </Box>
      <Box data-ws-id="fklPMWDP36anjRpXgMHa2" data-ws-component="Box" className="c12nfkjf cjoxzzt c126o250 c1l17u2a cr1yqkd c18mbfxh ct8i9vr c1pc5yxj c1p1fgry clox9rb c8cubvc c1sgbb1f cg69y67 c153hhwy c11gr7ii c1eg1wbf c10z6flc cqb9ahs c5au0ss c1au2fq0 c1du65tt c7e77dn c197a8a0 c4jn8d cdkrv0w c4g7r6e ctfo78j">
        <Box data-ws-id="07EAoPesHlUV-8sJzcgCR" data-ws-component="Box" className="c12nfkjf c1b4zs23 cgy0mkz c79hgj6">
          <Text data-ws-id="DWyns-5CymYWW8rO4BOCb" data-ws-component="Text" tag={"span"} className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj">
            {"Our new site is almost here! In the meantime, don't hesitate to reach out. We're ready to connect and elevate your digital journey. Get in touch today!"}
          </Text>
          <Link data-ws-id="LmhItW3XAPrYvkkf2kt4k" data-ws-component="Link" href={"/#CTA"} target={"_parent"} prefetch={"none"} className="c1b0psp3 cll3cci c18hcx4b cycdw1e c1v92b24 c1ymwiuh c1oc3pgx c1qijv48 cl0prib clxy39m cltdwpt csczotk c37r0ha cukv0dg c16jettk">
            <Bold data-ws-id="XQTwKuVYfsDdAguEi5SSo" data-ws-component="Bold">
              {"Get In Touch"}
            </Bold>
          </Link>
        </Box>
      </Box>
    </Box>
    <Box data-ws-id="F60YqIP_pA6vbskiIhPok" data-ws-component="Box" className="c18mbfxh cwixk71">
      <Box data-ws-id="d_LyuxCOJQGKOJje_pfoY" data-ws-component="Box" className="c6tu2fr ctr42qu cbv3mup c18mbfxh c1qs3ss9">
        <Box data-ws-id="CjJnQ4hqpTIUr59soJ-yO" data-ws-component="Box" className="c12nfkjf c1oopm3x c12i8rmd c18c3e4j ctxi0qe c1sgbb1f cg69y67 c153hhwy c11gr7ii c126o250 c1bdyqcj cxu56bx c1xhj0w5 cqj9kkp c4jn8d cdkrv0w c4g7r6e ctfo78j">
          <Box data-ws-id="kNInE-uyXgTX7_QZKTt1a" data-ws-component="Box" className="c12nfkjf c1l17u2a cr1yqkd c79hgj6">
            <Box data-ws-id="ym7vRGN0ngcCwEYq3vsfz" data-ws-component="Box" className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c12nfkjf cjoxzzt c1q8va2w c1lmoard">
              <Heading data-ws-id="FCDih6-z5ZfOfI8NPplI_" data-ws-component="Heading" tag={"h3"} className="c1odyjf c1lxwxiw c1oce3ix c16s8xlk">
                <Bold data-ws-id="oCRIh7wRfR5Sc5TzsA6AN" data-ws-component="Bold">
                  {"Research-Driven Design Solutions"}
                </Bold>
              </Heading>
              <Paragraph data-ws-id="K-ozmD8VdlNJTzbStDI5j" data-ws-component="Paragraph" className="c1odyjf c1lxwxiw">
                {"Dive deep into user-centric research to unveil actionable insights, ensuring designs that truly resonate with your audience."}
              </Paragraph>
            </Box>
            <Box data-ws-id="zO-wi96pzkbAoHhAaxmux" data-ws-component="Box" className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c12nfkjf cjoxzzt c1q8va2w c1lmoard">
              <Heading data-ws-id="ZbWA5bpLqLGNgPh1nC_vr" data-ws-component="Heading" tag={"h3"} className="c1odyjf c1lxwxiw c1oce3ix c16s8xlk">
                <Bold data-ws-id="q-Gi9BqhfUc-71whAAelX" data-ws-component="Bold">
                  {"Custom Website Development"}
                </Bold>
              </Heading>
              <Paragraph data-ws-id="ljdtKp12Q3YH-cmcvwnkg" data-ws-component="Paragraph" className="c1odyjf c1lxwxiw">
                {"From concept to creation, our team crafts visually stunning and functionally robust websites, tailored to your specific needs and goals."}
              </Paragraph>
            </Box>
            <Box data-ws-id="WWwZMFtMDVErDlx0UFB3e" data-ws-component="Box" className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c12nfkjf cjoxzzt c1q8va2w c1lmoard">
              <Heading data-ws-id="ue6KZMvsgt4VIcN6ICeOy" data-ws-component="Heading" tag={"h3"} className="c1odyjf c1lxwxiw c1oce3ix c16s8xlk">
                <Bold data-ws-id="JJMpCybSRjmu4Y5v4rtfe" data-ws-component="Bold">
                  {"API Integration Services"}
                </Bold>
              </Heading>
              <Paragraph data-ws-id="eLCrhGTcfRia0J6K2hJhW" data-ws-component="Paragraph" className="c1odyjf c1lxwxiw">
                {"Elevate your website's functionality with seamless API integrations, enabling smooth communication between your site and essential third-party services."}
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box data-ws-id="vPJHXEzwheS7G-C_vumBB" data-ws-component="Box" className="c85n6p8 c1sdrmnm ckqwaap crroenv cqv4916 cfx26zd cwixk71" />
    </Box>
    <Box data-ws-id="a8LAr82x0QQ4c3Z8pNNqr" data-ws-component="Box" id={"CTA"} className="cv3e7wi c4d4e0r c153hhwy c11gr7ii c1b4zs23 cgy0mkz c18mbfxh">
      <Box data-ws-id="j_l7BlITMsDVW9lc2x1DW" data-ws-component="Box" className="c85n6p8 c1sdrmnm ckqwaap crroenv cqv4916">
        <Image data-ws-id="Danfqy7xRMncXy3pCnf6b" data-ws-component="Image" src={"/assets/Man_sitting_5XKjRZTRoxDh7ycOvJoTR.jpg"} width={2688} height={2100} className="c1oh95cm c135xw6u ct8i9vr cq2ww50 c1gnju34 cp5b59h cidi7mz cyil8lz ciyzcg9" />
        <Box data-ws-id="zlsicwxGkVG5Rsxj1PjZ_" data-ws-component="Box" className="c85n6p8 c1sdrmnm ckqwaap crroenv cqv4916 cqd0jom ccsmljk" />
        <Box data-ws-id="lqBr5rkppsRdv134hItpL" data-ws-component="Box" className="c85n6p8 c1sdrmnm ckqwaap crroenv cqv4916 c10egpoc c184zlep cz2ykoi c1o8lfvu c1hiovje c3auimc cgx4hgl" />
      </Box>
      <Box data-ws-id="GAB6Xk0i_D922qTaNY4YF" data-ws-component="Box" className="c12nfkjf ct8i9vr c1pc5yxj ctr42qu cbv3mup c1p1fgry c1b4zs23 cgy0mkz c10z6flc cqb9ahs c5au0ss c1au2fq0 c1du65tt c7e77dn c197a8a0 c79hgj6">
        <Box data-ws-id="oi9p5jY1dVG6ZqmLuO5vB" data-ws-component="Box" className="c18mbfxh ct8i9vr c1pc5yxj c12nfkjf cjoxzzt c1q8va2w c1lmoard c10z6flc cqb9ahs">
          <Heading data-ws-id="1lcYWm8_UeFPDmx2-Rfp5" data-ws-component="Heading" tag={"h2"} className="c1odyjf c1lxwxiw c13cbewo c1oce3ix c16s8xlk">
            {"Transform Your Online Presence"}
          </Heading>
          <Text data-ws-id="tch4Fg55TgVx8G4CZ_Bmt" data-ws-component="Text">
            {"Our expertise in user research, bespoke design, and seamless integration forms the foundation of your digital transformation. Connect with us for inquiries or to discuss your project's vision."}
          </Text>
        </Box>
        <Form data-ws-id="ZcaLpgQhBnTPYtvU1Hnqy" data-ws-component="Form" state={formState} onStateChange={state => {
          formState = state;
          set$formState(formState);
        }} action={"https://directus-uxible-main-website-sw.uxible.io/flows/trigger/9a50a076-389b-46bd-a2d6-9615b3188af4"} className="c18mbfxh ct8i9vr c1pc5yxj c10z6flc cqb9ahs">
          {(formState === 'initial' || formState === 'error') && <Box data-ws-id="dO5KXNMrJzERLK_aKU5gn" data-ws-component="Box" className="c12nfkjf cjoxzzt c1b4zs23 cgy0mkz">
            <Box data-ws-id="X8PTW2yfkl8RC8Yt0oGll" data-ws-component="Box" className="c12nfkjf cjoxzzt c1q8va2w c1lmoard">
              <Box data-ws-id="Bd_YQL-b_x5YbgrTv_8o1" data-ws-component="Box">
                <Label data-ws-id="YU3DAXI1fnTrHEhdxp0JK" data-ws-component="Label">
                  {"Name"}
                </Label>
                <Input data-ws-id="knKsayoe2n5MuUPWuC4dB" data-ws-component="Input" name={"name"} type={"text"} required={true} autoFocus={false} className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c11hgwo5 c81vhqg ci34dv0 c1hxzlq5 c1dgecyw c1msjx6x cojnjc9 c1c3pr7s c12hsgls c1co0g5z cre5zrr cjrf5lz cv3ms1g c1gtdxn0" />
              </Box>
              <Box data-ws-id="gXL7jyosSdJ16KF01nZHJ" data-ws-component="Box">
                <Label data-ws-id="PWD8UojswD9o7VexQfrvC" data-ws-component="Label">
                  {"Email"}
                </Label>
                <Input data-ws-id="dlYWYjOUQEF6V0CgHN0Sf" data-ws-component="Input" name={"email"} type={"email"} required={true} autoFocus={false} className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c11hgwo5 c81vhqg ci34dv0 c1hxzlq5 c1dgecyw c1msjx6x cojnjc9 c1c3pr7s c12hsgls c1co0g5z cre5zrr cjrf5lz cv3ms1g c1gtdxn0" />
              </Box>
              <Box data-ws-id="NALBWKIu9GuCblDtVmrEW" data-ws-component="Box">
                <Label data-ws-id="iCx1T7wiKg7SQAroiWrV0" data-ws-component="Label">
                  {"Phone"}
                </Label>
                <Input data-ws-id="nS3S6umfCYOuKSh265QlK" data-ws-component="Input" name={"telephone"} type={"tel"} required={true} autoFocus={false} className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c11hgwo5 c81vhqg ci34dv0 c1hxzlq5 c1dgecyw c1msjx6x cojnjc9 c1c3pr7s c12hsgls c1co0g5z cre5zrr cjrf5lz cv3ms1g c1gtdxn0" />
              </Box>
              <Box data-ws-id="UYE9bcXYUFkDNDNpoHkQE" data-ws-component="Box" className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj">
                <Label data-ws-id="V-QSKjwLDpInbwLe50MQu" data-ws-component="Label">
                  {"Message"}
                </Label>
                <Textarea data-ws-id="qTCTSeu1meAFG-qRHkt9B" data-ws-component="Textarea" required={true} autoFocus={false} name={"message"} className="c10z6flc cqb9ahs ct8i9vr c1pc5yxj c11hgwo5 c81vhqg cojnjc9 c1c3pr7s c12hsgls c1co0g5z cre5zrr cjrf5lz cv3ms1g c1gtdxn0 ci34dv0 c1hxzlq5 c1dgecyw c1msjx6x" />
              </Box>
            </Box>
            <Button data-ws-id="N2nLBeVyUZrK8upzUd6fW" data-ws-component="Button" type={"submit"} className="c1s2tcfd c18hcx4b c1ymwiuh c1oc3pgx c1qijv48 cl0prib clxy39m cltdwpt csczotk c37r0ha cojnjc9 c1c3pr7s c12hsgls c1co0g5z ckt52sm c16jettk ckl77xc c1o5t2ve">
              <Bold data-ws-id="45tbUH3hcAbthMZ6t_3iY" data-ws-component="Bold">
                {"Submit"}
              </Bold>
            </Button>
          </Box>}
          {formState === 'success' && <Box data-ws-id="jzIlNftbEsFJx4z80na1G" data-ws-component="Box" className="c13cbewo c1oce3ix">
            {"Thank you for getting in touch!"}
          </Box>}
          {formState === 'error' && <Box data-ws-id="HVTbn6zNxq-As8s_HNyhc" data-ws-component="Box" className="c85n6p8 c1ywmgx9 ci34dv0 cdpvfxk">
            {"Sorry, something went wrong."}
          </Box>}
        </Form>
      </Box>
    </Box>
    <Box data-ws-id="POvNtiTPbJm65W2oHx2Sn" data-ws-component="Box" className="c10egpoc c18mbfxh">
      <Box data-ws-id="7dgq_u3FZNbL4pdQw6mZ9" data-ws-component="Box" className="ctr42qu cbv3mup c6tu2fr c1sgbb1f cg69y67 c153hhwy c11gr7ii c12nfkjf cjoxzzt clmqj2b c1q8va2w c1lmoard">
        <Text data-ws-id="dYqM2psHfSfYI_Z55hglb" data-ws-component="Text" className="cn3lqgq">
          {"©2024 Uxible Co., Ltd. All rights reserved."}
        </Text>
      </Box>
    </Box>
  </Body>;
};
_s(Page, "rClcBG8pgMslhCsVjt9QlCM59W0=");
_c = Page;
export { Page };
export const getRemixParams = ({
  ...params
}) => {
  return params;
};
export const pagesPaths = new Set([""]);
export const formsProperties = new Map([["ZcaLpgQhBnTPYtvU1Hnqy", {
  "action": "https://directus-uxible-main-website-sw.uxible.io/flows/trigger/9a50a076-389b-46bd-a2d6-9615b3188af4"
}]]);
var _c;
$RefreshReg$(_c, "Page");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;